import Cookies from 'js-cookie';
import { CONSOLE_URL, DOMAIN } from '../constants';
export * from './applyShareCode';
export * from './getEnv';

type ORIGIN_ADDRESS = 'WEBSITE_LOGIN';

/**
 * 获取控制台地址，如果没有将会返回登录地址
 * @param origin_address 跳转来源
 */
export function getConsoleUrl(origin_address?: ORIGIN_ADDRESS) {
  let consoleUrl = Cookies.get(CONSOLE_URL);

  if (!consoleUrl) {
    consoleUrl = '/login';
  } else {
    if (typeof origin_address === 'string') {
      consoleUrl += `?to_origin=${origin_address}`;
    }
  }
  return consoleUrl;
}
/**
 * 跳转到控制台页面
 * @param origin_address 跳转来源
 */
export function toConsole(origin_address?: ORIGIN_ADDRESS) {
  window.location.href = getConsoleUrl(origin_address);
}

export function openNewTab(url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
}

export function cls(...classList: string[]) {
  return classList.filter((i) => !!i).join(' ');
}
