import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import * as styles from './index.module.less';
import Modal from '../Modal';
import { SearchOutlined } from '@ant-design/icons';
import { cls } from '../../utils';

interface Result {
  id: string;
  frontmatter: {
    title: string;
    slug: string;
  };
  excerpt: string;
}

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

class SearchModal {
  visible: boolean = false;
}

export default function DocSearch({ className = '', style }: IProps) {
  const [query, setQuery] = useState('');
  const [searchModal, setSearchModal] = useState(new SearchModal());
  const [result, setResult] = useState<Result[]>([]);
  const timer = useRef<NodeJS.Timeout>();
  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery<{ allMarkdownRemark: { nodes: Result[] } }>(graphql`
    query HeaderQuery {
      allMarkdownRemark {
        nodes {
          id
          frontmatter {
            title
            slug
          }
          excerpt(pruneLength: 10000)
        }
      }
    }
  `);

  const searchModalCloseHandler = () => {
    setSearchModal((state) => ({ ...state, visible: false }));
  };
  const searchModalShowHandler = () => {
    setSearchModal((state) => ({ ...state, visible: true }));
  };

  return (
    <>
      <div className={cls(styles.search, className)} onClick={searchModalShowHandler}>
        <SearchOutlined />
        <span className="placeholder-text">输入关键字搜索文档</span>
      </div>
      <Modal visible={searchModal.visible} width={700} onClose={searchModalCloseHandler}>
        <div className={styles.searchInput}>
          <SearchOutlined />
          <input
            type="text"
            placeholder="输入要搜索的文档关键字"
            value={query}
            onChange={(e) => {
              const query = e.target.value;
              setQuery(query);
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                const val = query.toLocaleLowerCase().trim();
                if (!val) {
                  setResult([]);
                  return;
                }
                setResult(
                  nodes.filter((node) => {
                    return (
                      node.excerpt.toLocaleLowerCase().includes(val) ||
                      node.frontmatter.title.toLocaleLowerCase().includes(val)
                    );
                  }),
                );
              }, 500);
            }}
          />
        </div>
        <div className={styles.searchResult}>
          <div>
            {result.map((node) => {
              return (
                <Link to={`/doc${node.frontmatter.slug}`} key={node.id} className={styles.item}>
                  <div
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                      __html: formatDesc(node.frontmatter.title, query),
                    }}
                  />
                  <div
                    className={styles.desc}
                    dangerouslySetInnerHTML={{ __html: formatDesc(node.excerpt, query) }}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
}

function formatDesc(value: string, word: string) {
  const spliceLen = 100;
  const valueLen = value.length;
  const wordPos = value.indexOf(word);
  let newValue = value;

  if (valueLen > spliceLen) {
    if (wordPos < spliceLen) {
      newValue = newValue.substring(0, spliceLen) + '...';
    } else {
      newValue = newValue.substring(wordPos - 10, spliceLen + wordPos + word.length - 10) + '...';
    }
  }
  const reg = new RegExp(word, 'gi');
  return newValue.replace(reg, `<span class="active-word">${word}</span>`);
}
